export const simpleProps = [
  'resellerId',
  'address',
  'apartmentNumber',
  'buildingId',
  'uniqueCode',
  'customerCreationDate',
  'firstName',
  'lastName',
  'primaryEmail',
  'alternativeEmail',
  'phoneMobile',
  'phoneWork',
  'postCode'
]
