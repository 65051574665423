import { testCustomerForErrors } from './'

export function testForErrors (customers) {
  const errors = []

  const testCustomer = testCustomerForErrors.bind(this)

  customers.forEach((customer, index) => {
    // const customerErrors = testCustomerForErrors(customer, index)
    const customerErrors = testCustomer(customer, index)
    customerErrors?.length && errors.push(...customerErrors)
  })

  this.console = true

  return errors
}
