export const connectionDataFields = {
  Login: {
    PPPOE: '',
    IPoE: ''
  },
  PWD: {
    PPPOE: '',
    IPoE: ''
  },
  IP: 'IP',
  'Speed In': '',
  'Speed Out': '',
  'Routed subnet': '',
  'VLAN DGtek': '',
  'VLAN RSP': ''
}
