import { BaseController } from './'
import { testCustomerForFatalErrors, testForFatalErrors } from '../helpers'

class FatalErrorsController extends BaseController {
  constructor () {
    super('fatal-errors')

    this.testCustomerForFatalErrors = testCustomerForFatalErrors
    this.testForFatalErrors = testForFatalErrors
  }

  getBuildingError (index) {
    return this.items.filter(error => error.index === index && error.key === 'buildingId').length
  }

  getServiceError (customerIndex, serviceIndex = 0) {
    return this.items.filter(error => error.index === customerIndex && error.serviceIndex === serviceIndex && error.key === 'serviceId').length
  }

  testCustomer (customer, index) {
    this.removeAllCustomerRecords(index)

    this.add(this.testCustomerForFatalErrors(customer, index))
    this.emitEvent()
  }

  test (customers) {
    if (!customers) return console.warn('Error: Customers are not defined!')
    this.items = this.testForFatalErrors(customers)
    this.emitEvent()
  }
}

export const fatalErrorsController = new FatalErrorsController()
