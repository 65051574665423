import { addMessageToDebugConsole } from '@/helpers'

export function testCustomerForFatalErrors (customer, index) {
  const fatalErrors = []

  if (!customer) return console.warn('Test customer for fatal errors: Customer is not defined')

  const address = customer.address ? `${customer.apartmentNumber}/${customer.address}` : 'Address not defined'

  customer.services.forEach((service, serviceIndex) => {
    if (!service.id) {
      fatalErrors.push({ key: 'serviceId', index, serviceIndex, address, error: `Service ${service.serviceName} not found!` })
      if (!this.console) {
        addMessageToDebugConsole({
          key: 'invalid-service',
          title: 'Invalid service',
          message: `Service ${service.serviceName} not found for customer ${address}`,
          type: 'error'
        })
      }
    }
  })

  if (!customer.buildingId) {
    fatalErrors.push({ key: 'buildingId', index, address, error: 'building not found!' })
    if (!this.console) {
      addMessageToDebugConsole({
        key: 'invalid-address',
        title: 'Invalid address',
        message: `Building ${customer.address} not found`,
        type: 'error'
      })
    }
  }

  return fatalErrors
}
