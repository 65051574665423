export function changeServiceStatus (status) {
  Object.assign(this.services[this.serviceIndex], { status })
  const [services, canceled] = [
    this.services.length,
    this.services.filter(service => service.status === 'Canceled').length
  ]
  if (status !== 'Canceled') this.updateProperty('uniqueCode', this.uniqueCode.split('.canceled').join(''))
  else {
    this.updateProperty('uniqueCode', [this.uniqueCode, ''].join(canceled === services && this.uniqueCode.indexOf('.canceled') === -1 ? '.canceled' : ''))
  }
}
