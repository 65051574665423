import { requiredFields, messages } from './customer-controller/customerRequiredFields'
import { addMessageToDebugConsole } from '@/helpers'
import { testDate } from './testDate'

export function testCustomerForErrors (customer, index, serviceIndex = 0) {
  if (!customer) return console.warn('Test customer for errors: Customer is not defined')

  const errors = []
  const address = customer.address ? `${customer.apartmentNumber}/${customer.address}` : 'Address not defined!'

  const dateErrors = testDate(customer.services[serviceIndex])

  dateErrors.forEach(err => {
    const { title, ...rest } = err
    errors.push(Object.assign(rest, { index, address }))
    if (!this.console) {
      console.log('Testing for errors')
      addMessageToDebugConsole({ key: 'service-date-error', title, message: `${address}: ${rest.error}`, type: 'error' })
    }
  })

  const customerErrors = requiredFields
    .map((key, num) => ({ index, key, address, error: `${messages[num]} required` }))
    .filter(record => !customer[record.key])

  customerErrors && Array.isArray(customerErrors) && errors.push(...customerErrors)

  if (!this.console) {
    console.log('Testing for errors')
    for (const error of customerErrors) {
      addMessageToDebugConsole({
        key: 'required-fields',
        title: 'Required details',
        message: `${address}: ${error.error}`,
        type: 'error'
      })
    }
  }

  return errors
}
