export function testDate (service) {
  const title = 'Customer service error'

  const key = service.status === 'Awaiting to be suspended'
    ? 'suspendDate'
    : service.status === 'Suspended'
      ? 'suspendedDate'
      : service.status === 'Awaiting for cancelation'
        ? 'cancelDate'
        : service.status === 'Canceled'
          ? 'cancelDate'
          : 'activationDate'

  const errors = []

  !service.activationDate && errors.push({ key: 'activationDate', error: 'Service activation date invalid or missing' })

  if (service.status === 'Awaiting to be suspended') {
    if (!service.suspendDate) errors.push({ key, error: 'Invalid or missing date when service should be suspended' })
    else {
      if (service.activationDate && service.activationDate > service.suspendDate) {
        errors.push({
          key,
          error: `Date when service should be suspended ${service.suspendDate} is before the date of activation ${service.activationDate}`
        })
      }
    }
  }

  if (service.status === 'Suspended') {
    if (!service.suspendedDate) errors.push({ key, error: 'Invalid or missing date of suspension' })
    else {
      if (service.activationDate && service.activationDate > service.suspendedDate) {
        errors.push({
          key,
          error: `Date of suspension ${service.suspendedDate} is before the date of activation ${service.activationDate}`
        })
      }
    }
  }

  if (service.status === 'Awaiting for cancelation') {
    if (!service.cancelDate) errors.push({ key, error: 'Invalid or missing date when service should be canceled' })
    else {
      if (service.activationDate && service.activationDate > service.cancelDate) {
        errors.push({
          key,
          error: `Date when service should be canceled ${service.suspendedDate} is before the date of its activation ${service.activationDate}`
        })
      }
    }
  }

  if (service.status === 'Canceled') {
    if (!service.canceledDate) errors.push({ key, error: 'Invalid or missing date of cancelation' })
    else {
      if (service.activationDate && service.activationDate > service.canceledDate) {
        errors.push({
          key,
          error: `Date of cancelation ${service.canceledDate} is before the date of its activation ${service.activationDate}`
        })
      }
    }
  }

  return errors.map(error => Object.assign(error, { title }))
}
