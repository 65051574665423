import { testCustomerForFatalErrors } from './'

export function testForFatalErrors (customers) {
  const fatalErrors = []

  const testCustomer = testCustomerForFatalErrors.bind(this)

  customers.forEach((customer, index) => {
    // const customerFatalErrors = testCustomerForFatalErrors(customer, index)
    const customerFatalErrors = testCustomer(customer, index)
    customerFatalErrors?.length && fatalErrors.push(...customerFatalErrors)
  })

  this.console = true

  return fatalErrors
}
